import styled from '@emotion/styled';
import { Box, Theme } from '@mui/material';
import { colors } from '../../../utils/constant';

export const StyledSection = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '70vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingBottom: '24px',
  },
}));

export const StyledMapSection = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: colors.white,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  borderRadius: '8px',
  padding: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledFormSection = styled(Box)({
  backgroundColor: colors.whiteSmoke,
  padding: '24px',
  width: '100%',
  minHeight: '50vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '8px',
});

export const StyledFormBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));
