import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { FC, useState } from 'react';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const prestigeSecurityLocation = {
  lat: 48.5734053,
  lng: 7.7521113,
};

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

const MapWrapper: FC = () => {
  const [selectedPlace, setSelectedPlace] = useState<{ lat: number; lng: number } | null>(null);

  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap mapContainerStyle={containerStyle} center={prestigeSecurityLocation} zoom={15}>
        <Marker
          position={prestigeSecurityLocation}
          onClick={() => setSelectedPlace(prestigeSecurityLocation)}
        />

        {selectedPlace && (
          <InfoWindow position={selectedPlace} onCloseClick={() => setSelectedPlace(null)}>
            <div>
              <h2>PRESTIGE SECURITE SARL</h2>
              <p>2 Rue de Griebauval</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapWrapper;
