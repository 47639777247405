import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Theme, Typography } from '@mui/material';
import { colors } from '../utils/constant';

interface TypographyProps {
  theme: Theme;
  color?: string;
}

interface BackgroundBoxProps {
  backgroundImage?: string;
}

export const CenteredColumnBox = styled(Box)(({ theme }: { theme: Theme }) =>
  css({
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '16px',
    },
  }),
);

export const StyledBackgroundBox = styled(Box)<BackgroundBoxProps>(({ backgroundImage }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
}));

export const TitleTypography = styled(Typography)<TypographyProps>(({ theme, color }) => ({
  color: color || colors.white,
  fontSize: '34px',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px #000000',
  width: theme.breakpoints.down('sm') ? '100%' : '75%',
}));

export const DescriptionTypography = styled(Typography)<TypographyProps>(({ theme, color }) => ({
  color: color || colors.white,
  width: theme.breakpoints.down('sm') ? '100%' : '60%',
  textShadow: '1px 1px 2px #000000, 0px 0px 2px #000000',
}));
