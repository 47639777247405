import { Typography } from '@mui/material';
import { FC } from 'react';
import agentEvenementEntreStade from '../assets/agent-événement-entrée-stade.jpg';
import agentEvenementStade from '../assets/agent-événement-stade.jpg';
import agentEvenementTerrain from '../assets/agent-événement-terrain.jpg';
import camSurveillance from '../assets/Caméra de surveillance.jpg';
import communicationAgent from '../assets/Communication agent.jpg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import ServicePageTemplate from '../templates/ServicePageTemplate';
import { listItemProps } from '../utils/types';

const AgentDeSecuriteEvenementiel: FC = () => {
  const title = 'VOTRE AGENCE DE SÉCURITÉ À STRASBOURG';
  const bgImages = [camSurveillance, securityGuardBlack, communicationAgent, agentEvenementTerrain];

  const listItems: listItemProps[] = [
    { href: '#', primary: 'Les accès et issues,' },
    { href: '#', primary: 'les files d’attente,' },
    { href: '#', primary: 'la zone extérieure et intérieure,' },
    { href: '#', primary: 'la sortie,' },
    { href: '#', primary: 'le parking.' },
  ];

  return (
    <ServicePageTemplate
      title={title}
      bgImages={bgImages}
      firstInfo={{
        imgSrc: agentEvenementEntreStade,
        title: 'Entreprise de sécurité événementielle à Strasbourg',
        subtitle:
          'Nos agents sont à votre service pour assurer la sécurité durant les événements que vous organisez.',
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Protection des lieux</strong> <br /> et des personnes
          </Typography>
        ),
        firstParagraph: (
          <Typography variant="body1" gutterBottom>
            Nous proposons des solutions de sécurité adaptées à tous types d’événements : les
            mariages, les foires, les expositions, les concerts, les soirées privés, les événements
            sportifs, etc.
          </Typography>
        ),
        listItems,
        lastParagraph: (
          <Typography marginBottom="24px" marginTop="24px" variant="body1" gutterBottom>
            Nous pouvons aussi assurer la protection rapprochée de VIP.
          </Typography>
        ),
        caption:
          'Nous assurons la sécurisation de vos événements familiaux, professionnels, culturels ou sportifs.',
      }}
      secondInfo={{
        imgSrc: agentEvenementStade,
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Des événements</strong> <br />
            bien sécurisés
          </Typography>
        ),
        firstParagraph: `Préserver la sécurité du public et des biens mis en place est primordiale lors d’une festivité. Faites appel à notre entreprise pour prévenir et gérer les éventuels risques et incidents (mouvement de foule, incendie, accident...) qui peuvent se présenter.`,
        secondParagraph: `Nous mettons en place des services de sécurité appropriés suivant les lieux, la superficie et le nombre de personnes afin de garantir le bon déroulement des manifestations.`,
        thirdParagraph:
          'Nos agents spécialisés peuvent se mobiliser dans les environs de Strasbourg, Mulhouse et Le Mans.',
        caption: 'Nous mettons en place des services de sécurité appropriés à vos manifestations.',
      }}
    />
  );
};

export default AgentDeSecuriteEvenementiel;
