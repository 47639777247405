import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface TitleState {
  title: string;
  setTitle: (title: string) => void;
}

const enhancer = devtools((set) => ({
  title: '',
  setTitle: (title: string) => set({ title }),
}));

export const titleStore = create<TitleState>(
  (set, get, api) => enhancer(set, get, api) as TitleState,
);
