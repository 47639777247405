import styled from '@emotion/styled';
import { Box, Theme } from '@mui/material';
import { slideRightFromLeft } from '../../utils/keyframes';

export const CenteredColumnBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  gap: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '8px',
  [theme.breakpoints.up('sm')]: {
    padding: '16px',
  },
}));

export const AnimatedCenteredColumnBox = styled(CenteredColumnBox)`
  visibility: hidden;
  opacity: 0;
  animation: ${slideRightFromLeft} 1s ease-out;
  animation-delay: 1s;
  animation-fill-mode: forwards;
`;
