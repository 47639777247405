import { Typography } from '@mui/material';
import { FC } from 'react';
import agentMagasinEnPosition from '../assets/agent-magasin-en-position.jpg';
import agentVilleEnPosition from '../assets/agent-ville-en-position.jpg';
import agentDeSecuriteMagasinCommerce from '../assets/agentDeSecuriteMagasinCommerce.jpg';
import cameraDeSurveillance from '../assets/Caméra de surveillance.jpg';
import communicationAgent from '../assets/Communication agent.jpg';
import ServicePageTemplate from '../templates/ServicePageTemplate';

const AgentDeSecuriteMagasinCommerce: FC = () => {
  const title = 'Agent de sécurité magasin à Strasbourg';
  const bgImages = [agentDeSecuriteMagasinCommerce, cameraDeSurveillance, communicationAgent];

  return (
    <ServicePageTemplate
      title={title}
      bgImages={bgImages}
      firstInfo={{
        imgSrc: agentMagasinEnPosition,
        title: 'Agent de sécurité magasin à Strasbourg',
        subtitle: 'Nos agents de sécurité assurent la surveillance de vos magasins.',
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Sécurisation des centres</strong> <br /> commerciaux
          </Typography>
        ),
        firstParagraph: (
          <Typography variant="body1" gutterBottom>
            Dans le secteur commercial, notre mission concerne la sécurisation des magasins, des
            visiteurs et des marchandises. Nos agents de prévention vols sont présents à l’entrée et
            à l’arrière caisse pour repérer les individus suspects.
          </Typography>
        ),
        lastParagraph: (
          <>
            <Typography marginBottom="8px" marginTop="8px" variant="body1" gutterBottom>
              En cas d’infraction, nous interpellons les voleurs. La nuit, nous effectuons des
              rondes pour surveiller les sites. Vous pouvez également solliciter les services de nos
              agents de sécurité incendie.
            </Typography>
            <Typography marginBottom="16px" marginTop="8px" variant="body1" gutterBottom>
              Nous pouvons intervenir en cas de sinistre ou de départ de feu.
            </Typography>
          </>
        ),
        caption: 'Nous protégeons les locaux, les visiteurs et les marchandises',
      }}
      secondInfo={{
        imgSrc: agentVilleEnPosition,
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Spécialiste</strong> de la sécurité
          </Typography>
        ),
        firstParagraph: `Notre entreprise est spécialisée dans le domaine de la sécurité privée. Pour assurer la protection de vos locaux commerciaux, faites confiance à nos agents professionnels.`,
        secondParagraph: `Nous maîtrisons les procédures à suivre contre les actes malveillants. Nous mettons à votre disposition des moyens humains et techniques fiables.`,
        thirdParagraph: `Notre équipe effectue aussi des interventions sur alarme dans le cas d’un cambriolage. Nous nous déplaçons dans les environs de Strasbourg, Mulhouse et Le Mans.`,
        caption: 'Nous engageons des moyens humains et techniques fiables.',
      }}
    />
  );
};

export default AgentDeSecuriteMagasinCommerce;
