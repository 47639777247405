import styled from '@emotion/styled';
import { Box, Link, Theme } from '@mui/material';
import { colors } from '../../utils/constant';

export const StyledSection = styled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: colors.white,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '70vh',
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingBottom: '24px',
  },
}));

export const StyledTextSection = styled(Box)(({ theme }: { theme: Theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingTop: '24px',
  paddingBottom: '24px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledImageSection = styled('div')(({ theme }: { theme: Theme }) => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: '8px',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

export const StyledImage = styled('img')({
  'width': '100%',
  'height': 'auto',
  'borderRadius': '8px',
  'boxShadow': '0px 0px 10px 0px rgba(0,0,0,0.75)',
  'transition': 'transform 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
    transform: 'scale(1.2)',
  },
});

export const StyledLink = styled(Link)(() => ({
  'fontSize': '20px',
  'color': colors.white,
  'backgroundColor': colors.secondary,
  'borderRadius': '20px',
  'margin': '0 0 0 5px',
  'padding': '0 5px',
  '&:hover': {
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
  },
}));

export const StyledBoxSubTitleCall = styled(Box)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  margin: '24px 0 24px 0',
  flexDirection: 'row',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    margin: '24px auto 24px auto',
    flexDirection: 'column',
    textAlign: 'center',
  },
}));
