import { Box } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import agentPosition from '../assets/agentPosition.jpg';
import backSecurityGuard from '../assets/back-security-guard.jpg';
import securityGuardBlack2 from '../assets/security-guard-black-2.jpg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import ScrollToTopButton from '../atoms/ScrollToTopButton';
import Footer from '../organisms/Footer';
import ContactHome from '../organisms/homePage/ContactHome';
import InfoBetweenServices from '../organisms/InfoBetweenServices';
import HeaderServices from '../organisms/servicePages/HeaderServices';
import ServiceSection from '../organisms/servicePages/ServiceSection';
import { colors } from '../utils/constant';

const ContactezNous: FC = () => {
  // const theme = useTheme();
  const bgImages = [agentPosition, securityGuardBlack, backSecurityGuard, securityGuardBlack2];
  const [bgImage, setBgImage] = useState<string>(bgImages[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
    changeBackgroundImage();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(changeBackgroundImage, 10000);
    return () => clearInterval(intervalId);
  }, [bgImage]);

  const changeBackgroundImage = () => {
    const currentIndex = bgImages.indexOf(bgImage);
    const nextIndex = (currentIndex + 1) % bgImages.length;
    setBgImage(bgImages[nextIndex]);
  };

  return (
    <Fragment>
      <Box position="fixed" zIndex={10} bottom={24} right={24}>
        <ScrollToTopButton />
      </Box>
      <Parallax strength={1000} bgImage={bgImage}>
        <Box minHeight={{ xs: '40vh', sm: '50vh' }}>
          <HeaderServices title="VOTRE AGENCE DE SÉCURITÉ À STRASBOURG" />
        </Box>
      </Parallax>
      <Box bgcolor={colors.white} padding="24px">
        <InfoBetweenServices
          title="Contactez-nous pour toute demande"
          description="Pour toute demande de renseignements ou suggestions sur notre site, n'hésitez pas à nous contacter, nous vous répondrons dans les plus brefs délais."
        />
      </Box>
      <ServiceSection />
      <ContactHome />
      <Footer />
    </Fragment>
  );
};

export default ContactezNous;
