import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../utils/constant';

interface MobileDrawerProps {
  handleDrawerToggle: () => void;
  navItems: Array<{ title: string; path: string }>;
}

const MobileDrawer: FC<MobileDrawerProps> = ({
  handleDrawerToggle,
  navItems,
}: MobileDrawerProps) => {
  return (
    <Box
      onClick={handleDrawerToggle}
      textAlign="center"
      height="100vh"
      bgcolor={colors.primary}
      color={colors.white}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        PRESTIGE SÉCURITÉ
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton component={Link} to={item.path} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MobileDrawer;
