import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import { FC, HTMLInputTypeAttribute, useState } from 'react';
import { FieldErrors, useFormContext, UseFormRegisterReturn } from 'react-hook-form';
import { CustomTextField } from './inputFieldStyle';

interface InputFieldProps {
  register: UseFormRegisterReturn;
  error: FieldErrors;
  id: string;
  label: string;
  labelColor?: string;
  defaultValue?: string;
  placeholder?: string;
  isPassword?: boolean;
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute;
  numberOfLigne?: number;
  disabled?: boolean;
  helperText?: string;
  shrink?: boolean;
  tooltip?: string;
}

const InputField: FC<InputFieldProps> = ({
  register,
  error,
  id,
  label,
  placeholder,
  isPassword,
  type = 'text',
  labelColor,
  defaultValue,
  numberOfLigne = 1,
  disabled,
  helperText,
  isRequired,
  shrink,
  tooltip,
}) => {
  const { watch } = useFormContext();
  const fieldValue = watch(id);
  const [hide, setHide] = useState(isPassword);
  const isMultiline = numberOfLigne > 1;

  return (
    <Tooltip title={tooltip}>
      <CustomTextField
        id={id}
        size={isMultiline ? 'medium' : 'small'}
        labelColor={labelColor}
        disabled={disabled}
        multiline={isMultiline}
        rows={numberOfLigne}
        label={label}
        required={isRequired}
        defaultValue={defaultValue}
        error={Boolean(error[id])}
        helperText={(error[id]?.message as string) || helperText}
        variant="outlined"
        margin="normal"
        placeholder={placeholder}
        type={hide ? 'password' : type}
        {...register}
        InputLabelProps={{
          shrink: shrink ?? Boolean(fieldValue),
        }}
        InputProps={{
          endAdornment: isPassword && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setHide(!hide)}
                aria-label={hide ? 'Afficher le mot de passe' : 'Masquer le mot de passe'}
              >
                {hide ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  );
};

export default InputField;
