import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';
import { colors } from '../utils/constant';

export const CheckboxStyle = styled(Checkbox)({
  'textAlign': 'center',
  'color': colors.secondary,
  '&.Mui-checked': {
    color: colors.secondary,
  },
});
