export const colors = {
  primary: '#0a2a44',
  secondary: '#d4ad72',
  tertiary: '#e64636',
  black: '#1f1f1f',
  white: '#F7F3F3',
  whiteSmoke: 'rgba(247,243,243,0.9)',
  gray: '#9DA3B4',
  transparent: '#00000000',
};
