import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Parallax } from 'react-parallax';
import backSecurityGuard from '../../assets/back-security-guard.jpg';
import MapWrapper from '../../molecules/MapWrapper';
import { colors } from '../../utils/constant';
import FormCompany from '../FormCompany';
import FormPrivate from '../FormPrivate';
import { StyledMapSection, StyledSection } from './homePageStyle/contactHomeStyle';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    'id': `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ContactHome: FC = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Parallax strength={300} blur={{ min: -5, max: 15 }} bgImage={backSecurityGuard}>
      <StyledSection theme={theme}>
        <StyledMapSection theme={theme}>
          <Typography fontFamily="Mulish" variant="h4" gutterBottom>
            {`N'hésitez pas à nous contacter pour avoir davantage de renseignements.`}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {`Nous disposons des ressources humaines et matérielles qui nous permettent d'assurer la
            sécurité de votre habitation, de votre entreprise ou des événements que vous organisez.`}
          </Typography>
          <MapWrapper />
        </StyledMapSection>
        <Box sx={{ width: { xs: '100%', sm: '80%', md: '60%' }, bgcolor: 'background.paper' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs devis"
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            sx={{
              'width': '100%',
              '.MuiTabs-flexContainer': {
                width: '100%',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
              },
              '& .MuiTab-root': {
                'color': colors.primary,
                'opacity': 0.7,
                '&.Mui-selected': {
                  color: colors.secondary,
                  opacity: 1,
                },
              },
            }}
          >
            <Tab
              label="Professionnel"
              {...a11yProps(0)}
              sx={{ textTransform: 'none', fontWeight: 'bold' }}
            />
            <Tab
              label="Particulier"
              {...a11yProps(1)}
              sx={{ textTransform: 'none', fontWeight: 'bold' }}
            />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <FormCompany />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FormPrivate />
          </CustomTabPanel>
        </Box>
      </StyledSection>
    </Parallax>
  );
};

export default ContactHome;
