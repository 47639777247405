import styled from '@emotion/styled';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { colors } from '../../utils/constant';

export const StyledMainBox = styled(Box)({
  minHeight: '60vh',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
});

export const StyledOverlayBox = styled(Box)({
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  overflow: 'scroll',
});

export const StyledGridContainer = styled(Grid)({
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-around',
  opacity: 0.8,
});

export const StyledGridItem = styled(Grid)(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '21rem',
  textAlign: 'center',
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '1rem',
  marginLeft: '1rem',
  marginRight: '1rem',
  color: colors.white,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '4px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
  [theme.breakpoints.up('md')]: {
    padding: '3rem',
  },
}));

export const StyledTypography = styled(Typography)({
  marginY: '1rem',
});
