import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledButton, StyledContainer, StyledTypography } from './pageErreurStyle';

const PageErreur: FC = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const goToHome = () => navigate('/');

  return (
    <StyledContainer>
      <StyledTypography variant="h1">404 - Page non trouvée</StyledTypography>
      <StyledTypography variant="h6">
        Oups! La page que vous recherchez semble introuvable.
      </StyledTypography>
      <StyledTypography>
        Cela peut être dû à une adresse incorrecte ou à une page qui a été déplacée.
      </StyledTypography>
      <StyledButton onClick={goBack} color="primary" variant="contained">
        Revenir en arrière
      </StyledButton>
      <StyledButton onClick={goToHome} color="secondary" variant="outlined">
        {`Aller à la page d'accueil`}
      </StyledButton>
    </StyledContainer>
  );
};

export default PageErreur;
