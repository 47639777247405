import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useBreakpoints = () => {
  const theme = useTheme();
  const upXs = useMediaQuery(theme.breakpoints.up('xs'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  return { upXs, downSm, downMd, downLg };
};

export default useBreakpoints;
