import { Box, Divider, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import guardService from '../../assets/Service gardiennage.jpg';
import { colors } from '../../utils/constant';
import {
  StyledImage,
  StyledImageSection,
  StyledSection,
  StyledTextSection,
} from './secondInfoStyle';

export interface SecondInfoProps {
  caption?: string;
  titleParagraph?: ReactNode;
  firstParagraph?: string;
  secondParagraph?: string;
  thirdParagraph?: string;
  imgSrc?: string;
}

const SecondInfo: FC<SecondInfoProps> = ({
  titleParagraph,
  firstParagraph,
  secondParagraph,
  thirdParagraph,
  caption,
  imgSrc = guardService,
}) => {
  const theme = useTheme();

  return (
    <Box bgcolor={colors.white} padding="24px">
      <StyledSection theme={theme}>
        <StyledImageSection theme={theme}>
          <StyledImage src={imgSrc} alt="Service gardiennage" />
        </StyledImageSection>
        <StyledTextSection theme={theme} marginBottom="16px">
          {titleParagraph}
          <Typography marginBottom="16px" variant="body1" gutterBottom>
            {firstParagraph}
          </Typography>
          <Typography marginBottom="16px" variant="body1" gutterBottom>
            {secondParagraph}
          </Typography>
          <Typography marginBottom="16px" variant="body1" gutterBottom>
            {thirdParagraph}
          </Typography>
          <Divider
            sx={{
              marginLeft: '24px',
              borderWidth: '2px',
              width: '120px',
              backgroundColor: colors.secondary,
            }}
          />
          <Typography variant="body1" margin="16px" gutterBottom>
            {caption}
          </Typography>
          <Divider
            sx={{
              marginLeft: '24px',
              borderWidth: '2px',
              width: '30px',
              backgroundColor: colors.secondary,
            }}
          />
        </StyledTextSection>
      </StyledSection>
    </Box>
  );
};

export default SecondInfo;
