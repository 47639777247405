import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Accueil from '../pages/Accueil';
import AgentDeSecuriteEvenementiel from '../pages/AgentDeSecuriteEvenementiel';
import AgentDeSecuriteMagasinCommerce from '../pages/AgentDeSecuriteMagasinCommerce';
import AgentSecurite from '../pages/AgentSecurite';
import ContactezNous from '../pages/ContactezNous';
import InterventionSurAlarme from '../pages/InterventionSurAlarme';
import MentionsLegales from '../pages/MentionsLegales';
import PageErreur from '../pages/PageErreur';
import SecuriteIncendie from '../pages/SecuriteIncendie';
import ServiceDeGardiennage from '../pages/ServiceDeGardiennage';
import MainWrapper from '../templates/MainWrapper';
// import { titleStore } from '../stores/titleStore';

const Router: FC = () => {
  // const title = titleStore((state) => state.title);
  return (
    <BrowserRouter>
      <MainWrapper>
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/agent-securite" element={<AgentSecurite />} />
          <Route path="/servicede-gardiennage" element={<ServiceDeGardiennage />} />
          <Route path="/securiteincendie" element={<SecuriteIncendie />} />
          <Route path="/agentde-securite-evenementiel" element={<AgentDeSecuriteEvenementiel />} />
          <Route path="/interventionsur-alarme" element={<InterventionSurAlarme />} />
          <Route
            path="/agentde-securite-magasin-commerce"
            element={<AgentDeSecuriteMagasinCommerce />}
          />
          <Route path="/contactez-nous" element={<ContactezNous />} />
          <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="*" element={<PageErreur />} />
        </Routes>
      </MainWrapper>
    </BrowserRouter>
  );
};

export default Router;
