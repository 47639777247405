import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { Parallax } from 'react-parallax';
import { useNavigate } from 'react-router-dom';
import backSecurityGuard from '../../assets/back-security-guard.jpg';
import employeSecuriteFemme from '../../assets/employeSecuriteFemme.jpeg';
import CustomButton from '../../atoms/CustomButton';
import { colors } from '../../utils/constant';
import {
  StyledImage,
  StyledImageSection,
  StyledSection,
  StyledTextSection,
} from './homePageStyle/multiSkilledAgentsHomeStyle';

const MultiSkilledAgentsHome: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Parallax strength={300} blur={{ min: -5, max: 15 }} bgImage={backSecurityGuard}>
      <StyledSection theme={theme}>
        <StyledImageSection theme={theme}>
          <StyledImage src={employeSecuriteFemme} alt="Service de nuit" />
        </StyledImageSection>
        <StyledTextSection theme={theme}>
          <Typography fontFamily="Mulish" variant="h4" gutterBottom>
            <strong>Des agents</strong> <br /> polyvalents
          </Typography>
          <Typography marginBottom="24px" variant="body1" gutterBottom>
            {"Que vous vouliez engager un agent de sécurité de nuit, de centre commercial, d'hôtels ou pour " +
              'vos événements, notre équipe est à votre service.'}
          </Typography>
          <Typography marginBottom="24px" variant="body1" gutterBottom>
            {"Réactifs et autonomes, nos vigiles sont capables d'intervenir en temps réel en cas d'imprévu. " +
              'Nous mettons en œuvre tous les moyens humains et technologiques à notre disposition pour assurer ' +
              'le bon déroulement des interventions.'}
          </Typography>
          <Typography marginBottom="24px" variant="body1" gutterBottom>
            {"Nous sommes à l'écoute de vos besoins afin de proposer des solutions adaptées."}
          </Typography>
          <CustomButton
            textColor={colors.white}
            endIcon={<InfoOutlinedIcon />}
            onClick={() => navigate('/agent-securite')}
          >
            En savoir plus
          </CustomButton>
        </StyledTextSection>
      </StyledSection>
    </Parallax>
  );
};

export default MultiSkilledAgentsHome;
