import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Container, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import agentPosition from '../assets/agentPosition.jpg';
import backSecurityGuard from '../assets/back-security-guard.jpg';
import securityGuardBlack2 from '../assets/security-guard-black-2.jpg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import ScrollToTopButton from '../atoms/ScrollToTopButton';
import Footer from '../organisms/Footer';
import HeaderServices from '../organisms/servicePages/HeaderServices';
import { colors } from '../utils/constant';

const MentionsLegales: FC = () => {
  const bgImages = [agentPosition, securityGuardBlack, backSecurityGuard, securityGuardBlack2];
  const [bgImage, setBgImage] = useState<string>(bgImages[0]);

  const changeBackgroundImage = () => {
    const currentIndex = bgImages.indexOf(bgImage);
    const nextIndex = (currentIndex + 1) % bgImages.length;
    setBgImage(bgImages[nextIndex]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    changeBackgroundImage();
  }, []);

  return (
    <Fragment>
      <Box position="fixed" zIndex={10} bottom={24} right={24}>
        <ScrollToTopButton />
      </Box>
      <Parallax strength={1000} bgImage={bgImage}>
        <Box minHeight={{ xs: '40vh', sm: '50vh' }}>
          <HeaderServices title="VOTRE AGENCE DE SÉCURITÉ À STRASBOURG" />
        </Box>
      </Parallax>
      <Container component="main" maxWidth="md">
        <Paper style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h1" gutterBottom>
            Mentions Légales
          </Typography>
          <Typography variant="h4" gutterBottom>
            Confidentialité
          </Typography>
          <Typography paragraph>
            {
              "PRESTIGE SECURITE SARL n'enregistre pas d'informations personnelles permettant l'identification, à l'exception des formulaires que l'utilisateur est libre de remplir. Ces informations ne seront pas utilisées sans votre accord, nous les utiliserons seulement pour vous adresser des courriers, des brochures, des devis ou vous contacter."
            }
          </Typography>
          <Typography paragraph>
            {
              "Les informations recueillies sur les sites bénéficient de la protection de la loi \"Informatique et Libertés\" n° 78-17 du 06 janvier 1978. Elles bénéficient d'un droit d'accès, de rectification, d'opposition à communication et de suppression sur simple demande à PRESTIGE SECURITE SARL, 2 rue gribeauval, 67100 STRASBOURG."
            }
          </Typography>
          <Typography paragraph>
            {
              "PRESTIGE SECURITE SARL pourra procéder à des analyses statistiques sans que celles-ci soient nominatives et pourra en informer des tiers (organismes d'évaluation de fréquentation) sous une forme résumée et non nominative."
            }
          </Typography>
          <Typography variant="h4" gutterBottom>
            Règlement général sur la protection des données (RGPD)
          </Typography>
          <Typography paragraph>
            {
              "PRESTIGE SECURITE SARL s'engage à ce que la collecte et le traitement de vos données, effectués à partir du site prestige-securite-strasbourg.com, soient conformes au règlement général sur la protection des données (RGPD). Chaque formulaire ou téléservice limite la collecte des données personnelles au strict nécessaire (minimisation des données) et indique notamment :"
            }
          </Typography>
          <List>
            <ListItem disablePadding>
              <ChevronRightIcon sx={{ color: colors.secondary }} />
              <ListItemText primary="quels sont les objectifs du recueil de ces données," />
            </ListItem>
            <ListItem disablePadding>
              <ChevronRightIcon sx={{ color: colors.secondary }} />
              <ListItemText primary="si ces données sont obligatoires ou facultatives pour la gestion de votre demande," />
            </ListItem>
            <ListItem disablePadding>
              <ChevronRightIcon sx={{ color: colors.secondary }} />
              <ListItemText primary="qui pourra en prendre connaissance (uniquement PRESTIGE SECURITE SARL en principe, sauf précision dans le formulaire lorsqu'une transmission à un tiers est nécessaire à la gestion de votre demande)," />
            </ListItem>
          </List>
          <Typography paragraph>
            {
              'Les données personnelles recueillies dans le cadre des services proposés sur prestige-securite-strasbourg.com sont traitées selon des protocoles sécurisés et permettent à PRESTIGE SECURITE SARL de gérer les demandes reçues dans ses applications informatiques.'
            }
          </Typography>
          <Typography paragraph>
            {
              'Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de données personnelles gérés par PRESTIGE SECURITE SARL, vous pouvez contacter PRESTIGE SECURITE SARL et éventuellement son délégué à la protection des données (DPO), ou vous tourner vers la CNIL.'
            }
          </Typography>
          <Typography variant="h5" gutterBottom>
            Utilisation de cookies
          </Typography>
          <Typography paragraph>
            {
              'Les cookies permettent d’enregistrer les informations (non personnelles) relatives à la navigation des utilisateurs sur internet. Les cookies ont pour objectif l’amélioration de l’expérience de navigation des visiteurs ainsi que l’optimisation des statistiques. Dès lors que vous les acceptés, ceux-ci ne seront utilisés uniquement dans le cadre de votre navigation sur le site prestige-securite-strasbourg.com, et ce pour une durée de 6 mois maximum.'
            }
          </Typography>
          <Typography variant="h6" gutterBottom>
            Liens hypertexte
          </Typography>
          <Typography paragraph>
            {
              "PRESTIGE SECURITE SARL ne contrôle pas les sites en connexion avec le sien, et ne saurait donc être responsable de leur contenu. Les risques liés à l'utilisation de ces sites incombent pleinement à l'utilisateur. Il se conformera à leurs conditions d'utilisation."
            }
          </Typography>
          <Typography variant="h6" gutterBottom>
            Éditeur
          </Typography>
          <Typography variant="body1">{'PRESTIGE SECURITE SARL'}</Typography>
          <Typography variant="body1">{'RCS STRASBOURG : 81830015400031'}</Typography>
          <Typography paragraph>{'Siège social : 2 rue gribeauval, 67100 STRASBOURG.'}</Typography>
          <Typography paragraph>{'N° de téléphone : 03 88 31 59 50'}</Typography>
          <Typography variant="h6" gutterBottom>
            Directeur de la publication
          </Typography>
          <Typography paragraph>{'ISLAM JERJIR'}</Typography>
        </Paper>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default MentionsLegales;
