import styled from '@emotion/styled';
import { Button, CircularProgress, Theme, useTheme } from '@mui/material';
import React, { JSX } from 'react';

/**
 * CustomButtonProps est un type qui définit les propriétés du composant CustomButton.
 * @property {string} children - Le contenu du bouton.
 * @property {'text' | 'outlined' | 'contained'} variant - Le style du bouton.
 * @property {() => void} onClick - La fonction a exécuté lors du clic sur le bouton.
 */
interface CustomButtonProps {
  children: string;
  onClick?: () => void;
  textColor?: string;
  backgroundColor?: string;
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  margin?: string;
  padding?: string;
  disabled?: boolean;
  loading?: boolean;
}

/**
 * CustomButton est un composant fonctionnel qui rend un bouton stylisé.
 * @param {CustomButtonProps} props - Les propriétés qui définissent le bouton.
 * @return {JSX.Element} Un élément de bouton stylisé.
 */
const CustomButton: React.FC<CustomButtonProps> = ({
  variant = 'contained',
  onClick,
  startIcon,
  endIcon,
  children,
  backgroundColor,
  textColor,
  type,
  margin,
  padding = '6px 16px',
  disabled = false,
  loading = false,
}: CustomButtonProps): JSX.Element => {
  const theme = useTheme();

  return (
    <StyledButton
      type={type}
      theme={theme}
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled || loading}
      sx={{
        margin,
        padding,
        'backgroundColor': backgroundColor ?? theme.palette.secondary.main,
        'color': textColor ?? theme.palette.primary.main,
        '&:hover': {
          backgroundColor: textColor ?? theme.palette.primary.main,
          color: backgroundColor ?? theme.palette.secondary.main,
        },
      }}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : children}
    </StyledButton>
  );
};

export default CustomButton;

/**
 * StyledButton est un composant stylé qui étend le composant MUI Button.
 */
const StyledButton = styled(Button)<{ theme: Theme }>(() => ({
  minWidth: '170px',
  fontWeight: '600',
  borderRadius: '20px',
  fontSize: '14px',
}));
