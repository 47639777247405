import { Box } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import ScrollToTopButton from '../atoms/ScrollToTopButton';
import Footer from '../organisms/Footer';
import FirstInfo, { FirstInfoProps } from '../organisms/servicePages/FirstInfo';
import HeaderServices from '../organisms/servicePages/HeaderServices';
import SecondInfo, { SecondInfoProps } from '../organisms/servicePages/SecondInfo';
import ServiceSection from '../organisms/servicePages/ServiceSection';

interface ServicePageTemplateProps {
  title: string;
  bgImages: string[];
  firstInfo?: FirstInfoProps;
  secondInfo?: SecondInfoProps;
}

const ServicePageTemplate: FC<ServicePageTemplateProps> = ({
  title,
  bgImages,
  firstInfo,
  secondInfo,
}) => {
  const [bgImage, setBgImage] = useState<string>(bgImages[0]);

  const changeBackgroundImage = () => {
    const currentIndex = bgImages.indexOf(bgImage);
    const nextIndex = (currentIndex + 1) % bgImages.length;
    setBgImage(bgImages[nextIndex]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    changeBackgroundImage();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(changeBackgroundImage, 10000);
    return () => clearInterval(intervalId);
  }, [bgImage]);

  return (
    <Fragment>
      <Box position="fixed" zIndex={10} bottom={24} right={24}>
        <ScrollToTopButton />
      </Box>
      <Parallax strength={1000} bgImage={bgImage}>
        <Box minHeight={{ xs: '40vh', sm: '50vh' }}>
          <HeaderServices title={title} />
        </Box>
      </Parallax>
      <FirstInfo
        imgSrc={firstInfo?.imgSrc}
        listItems={firstInfo?.listItems}
        title={firstInfo?.title}
        subtitle={firstInfo?.subtitle}
        titleParagraph={firstInfo?.titleParagraph}
        firstParagraph={firstInfo?.firstParagraph}
        lastParagraph={firstInfo?.lastParagraph}
        caption={firstInfo?.caption}
      />
      <ServiceSection />
      <SecondInfo
        imgSrc={secondInfo?.imgSrc}
        titleParagraph={secondInfo?.titleParagraph}
        firstParagraph={secondInfo?.firstParagraph}
        secondParagraph={secondInfo?.secondParagraph}
        thirdParagraph={secondInfo?.thirdParagraph}
        caption={secondInfo?.caption}
      />
      <Footer />
    </Fragment>
  );
};

export default ServicePageTemplate;
