import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
  padding: '20px',
  backgroundColor: '#f4f4f9',
});

export const StyledTypography = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '20px',
});

export const StyledButton = styled(Button)({
  marginTop: '20px',
  padding: '10px 20px',
  fontSize: '1rem',
});
