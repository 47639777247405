import { useTheme } from '@mui/material';
import { FC } from 'react';
import { Parallax } from 'react-parallax';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../atoms/CustomButton';
import { colors } from '../utils/constant';
import {
  CenteredColumnBox,
  DescriptionTypography,
  TitleTypography,
} from './infoBetweenServicesHomeStyle';

interface InfoBetweenServicesHomeProps {
  title: string;
  description: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  buttonLabel?: string;
}

const InfoBetweenServices: FC<InfoBetweenServicesHomeProps> = ({
  title,
  description,
  height = '40vh',
  color = colors.white,
  backgroundImage,
  buttonLabel,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Parallax strength={1000} bgImage={backgroundImage}>
      <CenteredColumnBox
        height={height}
        theme={theme}
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <TitleTypography variant="h2" theme={theme} color={color}>
          {title}
        </TitleTypography>
        <DescriptionTypography theme={theme} variant="h6" color={color}>
          {description}
        </DescriptionTypography>
        {buttonLabel && <CustomButton onClick={() => navigate('/')}>{buttonLabel}</CustomButton>}
      </CenteredColumnBox>
    </Parallax>
  );
};

export default InfoBetweenServices;
