import { Box, Link, Typography } from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import agentPosition from '../assets/agentPosition.jpg';
import backSecurityGuard from '../assets/back-security-guard.jpg';
import securityGuardBlack2 from '../assets/security-guard-black-2.jpg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import ScrollToTopButton from '../atoms/ScrollToTopButton';
import Footer from '../organisms/Footer';
import FirstInfo from '../organisms/servicePages/FirstInfo';
import HeaderServices from '../organisms/servicePages/HeaderServices';
import SecondInfo from '../organisms/servicePages/SecondInfo';
import ServiceSection from '../organisms/servicePages/ServiceSection';
import { colors } from '../utils/constant';
import { listItemProps } from '../utils/types';

const AgentSecurite: FC = () => {
  const bgImages = [agentPosition, securityGuardBlack, backSecurityGuard, securityGuardBlack2];
  const [bgImage, setBgImage] = useState<string>(bgImages[0]);

  const listItems: listItemProps[] = [
    { href: '/securiteincendie', primary: 'Sécurité incendie,' },
    { href: '/agentde-securite-evenementiel', primary: 'Sécurité événementielle,' },
    { href: '/agentde-securite-magasin-commerce', primary: 'Sécurité magasin,' },
    { href: '/servicede-gardiennage', primary: 'Sécurité gardiennage.' },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    changeBackgroundImage();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(changeBackgroundImage, 10000);
    return () => clearInterval(intervalId);
  }, [bgImage]);

  const changeBackgroundImage = () => {
    const currentIndex = bgImages.indexOf(bgImage);
    const nextIndex = (currentIndex + 1) % bgImages.length;
    setBgImage(bgImages[nextIndex]);
  };

  return (
    <Fragment>
      <Box position="fixed" zIndex={10} bottom={24} right={24}>
        <ScrollToTopButton />
      </Box>
      <Parallax strength={1000} bgImage={bgImage}>
        <Box minHeight={{ xs: '40vh', sm: '50vh' }}>
          <HeaderServices title="VOTRE AGENCE DE SÉCURITÉ À STRASBOURG" />
        </Box>
      </Parallax>
      <FirstInfo
        title="Agent de sécurité à Strasbourg"
        subtitle="Notre agence de sécurité assure la protection des personnes et des biens."
        listItems={listItems}
        titleParagraph={
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Nos prestations</strong> <br /> en matière de sécurité
          </Typography>
        }
        firstParagraph={
          <Typography variant="body1" gutterBottom>
            Nous répondons à vos besoins en matière de
            <Link href="#" underline="hover" color={colors.secondary}>
              {' gardiennage'}
            </Link>{' '}
            et de sécurité privée. Nous mettons nos agents à votre service pour diverses missions :
          </Typography>
        }
        lastParagraph={
          <Typography marginBottom="24px" marginTop="32px" variant="body1" gutterBottom>
            Que vous souhaitiez engager des agents fixes ou des agents mobiles, notre équipe de
            vigiles professionnels sont toujours prêts pour assurer votre protection. Nos
            prestations sont proposées aux professionnels comme aux particuliers.
          </Typography>
        }
        caption="Nous proposons des services de gardiennage"
      />
      <ServiceSection />
      <SecondInfo
        imgSrc={backSecurityGuard}
        titleParagraph={
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Agents rigoureux</strong> <br />
            et professionnels
          </Typography>
        }
        firstParagraph="Nos agents de sécurité maîtrisent parfaitement leurs fonctions. Ayant une bonne condition physique et dotés d’un grand sens de l’observation, nous sommes aptes à faire face aux différentes situations."
        secondParagraph="Nous nous adaptons aux demandes de nos clients en mettant au point des solutions personnalisées. Comptez sur un service remarquable de la part de notre équipe de professionnels rigoureux."
        thirdParagraph="Nous intervenons dans les villes de Strasbourg, Mulhouse, Mans et des environs."
        caption="Nos agents de sécurité sont dotés d’un grand sens de l’observation."
      />
      <Footer />
    </Fragment>
  );
};

export default AgentSecurite;
