import styled from '@emotion/styled';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import logoPrestige from '../assets/logo.png';
import useBreakpoints from '../services/hooks/useBreakpoints';

export interface ImageProps {
  src?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  sxContainer?: SxProps<Theme>;
  href?: string;
}

const Image: FC<ImageProps> = ({
  width = 'auto',
  height = 'auto',
  src = logoPrestige,
  alt = 'logo prestige securite strasbourg',
  sxContainer,
  className,
  href = '/',
}: ImageProps) => {
  const { downSm } = useBreakpoints();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={downSm ? 'center' : 'flex-start'}
      justifyContent="center"
      sx={sxContainer}
    >
      <a href={href}>
        <ImageBox width={width} height={height} src={src} alt={alt} className={className} />
      </a>
    </Box>
  );
};

export default Image;

const ImageBox = styled.img({
  'transition': 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
});
