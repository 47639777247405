import { Box, FormControlLabel, Typography } from '@mui/material';
import { FC } from 'react';
import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { CheckboxStyle } from './checkboxStyle';

interface CheckboxProps {
  id: string;
  register: UseFormRegisterReturn;
  error: FieldErrors;
  label?: string;
}

const Checkbox: FC<CheckboxProps> = ({ id, register, error, label }) => {
  return (
    <Box display="flex" flexDirection="column">
      <FormControlLabel control={<CheckboxStyle id={id} {...register} />} label={label || ''} />
      <Typography variant="caption" color="red">
        {error[id]?.message as string}
      </Typography>
    </Box>
  );
};

export default Checkbox;
