import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import DrawerAppBar from '../organisms/DrawerAppBar';
import { useDocumentTitle } from '../services/hooks/useDocumentTitle';
import { colors } from '../utils/constant';

interface MainWrapperProps {
  children: ReactNode;
}

const MainWrapper: FC<MainWrapperProps> = ({ children }) => {
  useDocumentTitle();

  return (
    <>
      <DrawerAppBar />
      <Box
        component="main"
        minHeight="100vh"
        bgcolor={colors.primary}
        sx={{ marginTop: { xs: '54px', sm: '64px' } }}
      >
        {children}
      </Box>
    </>
  );
};

export default MainWrapper;
