import { Grid } from '@mui/material';
import { FC } from 'react';
import {
  StyledContentBox,
  StyledDescriptionTypography,
  StyledIconBox,
  StyledImageBox,
  StyledPaper,
  StyledTitleSectionTypography,
  StyledTitleTypography,
  StyledTrustSectionBox,
} from './homePageStyle/trustSectionStyle';

interface TrustReasonProps {
  title: string;
  description: string;
  srcImage: string;
  icon: React.ReactNode;
}

const TrustReason: FC<TrustReasonProps> = ({ title, description, icon, srcImage }) => {
  return (
    <StyledPaper elevation={5}>
      <StyledImageBox src={srcImage} alt="image de fond" />
      <StyledContentBox>
        <StyledIconBox>{icon}</StyledIconBox>
        <StyledTitleTypography variant="body1">{title}</StyledTitleTypography>
        <StyledDescriptionTypography variant="caption">{description}</StyledDescriptionTypography>
      </StyledContentBox>
    </StyledPaper>
  );
};

interface TrustSectionProps {
  reasons: TrustReasonProps[];
}

const TrustSection: FC<TrustSectionProps> = ({ reasons }) => {
  return (
    <StyledTrustSectionBox>
      <StyledTitleSectionTypography variant="h4">
        Pourquoi nous faire confiance ?
      </StyledTitleSectionTypography>
      <Grid container spacing={2} justifyContent="center">
        {reasons.map((reason, index) => (
          <Grid key={index} item xs={12} md={4}>
            <TrustReason {...reason} />
          </Grid>
        ))}
      </Grid>
    </StyledTrustSectionBox>
  );
};

export default TrustSection;
