import { boolean, object, string } from 'yup';

export const useValidationSchemas = () => {
  const sendToCompanySchema = object({
    companyName: string().required('Le nom de la compagnie est requis'),
    contact: string().required('Le nom du contact est requis'),
    email: string().email('Doit être un email valide').required("L'email est requis"),
    phone: string().required('Le numéro de téléphone est requis'),
    typeEvent: string().required("Le type d'événement est requis"),
    startDate: string().required('La date de début est requise'),
    endDate: string().required('La date de fin est requise'),
    agreeCollectedInformation: boolean().oneOf([true], 'Vous devez accepter les conditions.'),
    siren: string().optional(),
    address: string().optional(),
    city: string().optional(),
    postCode: string().optional(),
    location: string().optional(),
    numberPeopleExpected: string().optional(),
    numberAgentsRequired: string().optional(),
    details: string().optional(),
  });

  const sendToPrivateSchema = object().shape({
    firstname: string().required('Le prénom est requis'),
    lastname: string().required('Le nom de famille est requis'),
    email: string().email('Doit être un email valide').required("L'email est requis"),
    phone: string().required('Le numéro de téléphone est requis'),
    typeEvent: string().required("Le type d'événement est requis"),
    startDate: string().required('La date de début est requise'),
    endDate: string().required('La date de fin est requise'),
    agreeCollectedInformation: boolean().oneOf([true], 'Vous devez accepter les conditions.'),
    city: string().optional(),
    postCode: string().optional(),
    address: string().optional(),
    location: string().optional(),
    numberPeopleExpected: string().optional(),
    numberAgentsRequired: string().optional(),
    details: string().optional(),
  });

  return { sendToCompanySchema, sendToPrivateSchema };
};
