import { yupResolver } from '@hookform/resolvers/yup';
import { FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Checkbox from '../atoms/Checkbox';
import CustomButton from '../atoms/CustomButton';
import InputField from '../molecules/InputField';
import { useValidationSchemas } from '../services/hooks/useValidationSchemas';
import { CompanyQuote } from '../utils/types';
import { StyledFormBox, StyledFormSection } from './homePage/homePageStyle/contactHomeStyle';

const FormCompany: FC = () => {
  const theme = useTheme();
  const { sendToCompanySchema } = useValidationSchemas();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formMethods = useForm<any>({
    resolver: yupResolver(sendToCompanySchema),
    defaultValues: {
      agreeCollectedInformation: false,
    },
  });

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const formatEmailBody = (formData: CompanyQuote) => {
    const formatDate = (date: Date) => {
      return new Intl.DateTimeFormat('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date);
    };

    const fields = [
      { label: 'Nom de la compagnie', value: formData.companyName },
      { label: 'Contact', value: formData.contact },
      { label: 'Email', value: formData.email },
      { label: 'Téléphone', value: formData.phone },
      { label: "Type d'événement", value: formData.typeEvent },
      { label: "Début de l'événement", value: formatDate(new Date(formData.startDate)) },
      { label: "Fin de l'événement", value: formatDate(new Date(formData.endDate)) },
    ];

    if (formData.siren) fields.push({ label: 'SIREN', value: formData.siren });
    if (formData.address) fields.push({ label: 'Adresse', value: formData.address });
    if (formData.city) fields.push({ label: 'Ville', value: formData.city });
    if (formData.postCode) fields.push({ label: 'Code postal', value: formData.postCode });
    if (formData.location) fields.push({ label: "Lieu de l'événement", value: formData.location });
    if (formData.numberPeopleExpected)
      fields.push({
        label: 'Nombre de personnes attendues',
        value: formData.numberPeopleExpected.toString(),
      });
    if (formData.numberAgentsRequired)
      fields.push({
        label: "Nombre d'agents requis",
        value: formData.numberAgentsRequired.toString(),
      });
    if (formData.details) fields.push({ label: 'Détails', value: formData.details });

    const htmlContent = fields
      .map((field) => `<tr><td><strong>${field.label}:</strong></td><td>${field.value}</td></tr>`)
      .join('');

    const textContent = fields.map((field) => `${field.label}: ${field.value}`).join('\n');

    return {
      subject: "Nouvelle demande de devis d'une entreprise",
      text: `Un nouveau formulaire a été soumis avec les détails suivants:\n\n${textContent}`,
      html: `
      <html lang='fr'>
        <body>
          <p>Nouvelle demande de devis pour une entreprise Rempli le : ${formatDate(new Date())} </p>
          <table>${htmlContent}</table>
          <p>Cet email a été généré par le formulaire web de Prestige Sécurité.</p>
        </body>
      </html>
    `,
    };
  };

  const sendEmail = async (formData: CompanyQuote) => {
    setIsSubmitting(true);
    const emailBody = formatEmailBody(formData);

    try {
      const response = await axios.post(
        'https://us-central1-prestige-securite.cloudfunctions.net/sendEmailToCommercial',
        emailBody,
      );

      if (response.status === 200) {
        reset();
        alert('Email envoyé avec succès !');
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email :", error);
      alert("Erreur lors de l'envoi de l'email.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <StyledFormSection theme={theme}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(sendEmail)}>
          <Typography variant="h5">{`Demande de devis - Professionnel`}</Typography>
          <Typography variant="h6">{`Informations essentielles`}</Typography>
          <StyledFormBox theme={theme}>
            <InputField
              id="companyName"
              label="Nom de la Compagnie"
              register={register('companyName')}
              error={errors}
              tooltip="Nom officiel de votre entreprise tel qu'il apparaît sur les documents légaux"
            />
            <InputField
              id="contact"
              label="Nom du Contact"
              error={errors}
              register={register('contact')}
              tooltip="Personne à contacter pour le suivi de la demande"
            />
          </StyledFormBox>
          <StyledFormBox theme={theme}>
            <InputField
              id="email"
              label="Email"
              register={register('email')}
              error={errors}
              tooltip="Adresse email professionnelle pour les communications"
            />
            <InputField
              id="phone"
              label="Téléphone"
              register={register('phone')}
              error={errors}
              tooltip="Numéro de téléphone où nous pouvons vous joindre"
            />
          </StyledFormBox>

          <Typography variant="h6">{`Détails de l'événement`}</Typography>
          <StyledFormBox theme={theme}>
            <InputField
              label="Type d'événement"
              id="typeEvent"
              register={register('typeEvent')}
              error={errors}
              tooltip="Ex: Mariage, Concert, Foire commerciale, etc."
            />
          </StyledFormBox>
          <StyledFormBox theme={theme}>
            <InputField
              shrink
              id="startDate"
              label="Date de début"
              register={register('startDate')}
              error={errors}
              type="datetime-local"
              tooltip="Date et heure précises du début de l'événement"
            />
            <InputField
              shrink
              id="endDate"
              label="Date de fin"
              register={register('endDate')}
              error={errors}
              type="datetime-local"
              tooltip="Date et heure prévues pour la fin de l'événement"
            />
          </StyledFormBox>

          <Typography variant="h6">{`Informations complémentaires (optionnelles)`}</Typography>
          <StyledFormBox theme={theme}>
            <InputField
              id="siren"
              label="SIREN"
              register={register('siren')}
              error={errors}
              tooltip="Numéro unique d'identification à 9 chiffres attribué par l'INSEE"
            />
            <InputField
              id="address"
              label="Adresse"
              register={register('address')}
              error={errors}
              tooltip="Adresse de l'entreprise"
            />
          </StyledFormBox>
          <StyledFormBox theme={theme}>
            <InputField
              id="city"
              label="Ville"
              register={register('city')}
              error={errors}
              tooltip="Ville du siège de l'entreprise"
            />
            <InputField
              id="postCode"
              label="Code postal"
              register={register('postCode')}
              error={errors}
              tooltip="Code postal à 5 chiffres de la ville"
            />
          </StyledFormBox>
          <StyledFormBox theme={theme}>
            <InputField
              label="Lieu de l'événement"
              id="location"
              register={register('location')}
              error={errors}
              tooltip="Adresse où l'événement aura lieu"
            />
          </StyledFormBox>
          <StyledFormBox theme={theme}>
            <InputField
              id="numberPeopleExpected"
              label="Nombre de personnes attendues"
              register={register('numberPeopleExpected')}
              error={errors}
              type="number"
              tooltip="Estimation du nombre total de participants à l'événement"
            />
            <InputField
              id="numberAgentsRequired"
              label="Nombre d'agents requis"
              register={register('numberAgentsRequired')}
              error={errors}
              type="number"
              tooltip="Nombre d'agents de sécurité que vous pensez nécessaires (nous pourrons ajuster si besoin)"
            />
          </StyledFormBox>
          <InputField
            id="details"
            label="Détails supplémentaires"
            register={register('details')}
            error={errors}
            numberOfLigne={3}
            tooltip="Toute information complémentaire utile pour la sécurité de votre événement"
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="agreeCollectedInformation"
                  error={errors}
                  register={register('agreeCollectedInformation')}
                />
              }
              label="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre strict de ma demande*"
            />
          </FormGroup>
          <CustomButton margin="8px" type="submit" disabled={isSubmitting} loading={isSubmitting}>
            Envoyer
          </CustomButton>
        </form>
      </FormProvider>
    </StyledFormSection>
  );
};

export default FormCompany;
