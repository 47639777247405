import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { FaFileSignature, FaMapMarkedAlt } from 'react-icons/fa';
import { GrUserExpert } from 'react-icons/gr';
import securityStore from '../../assets/agentDeSecuriteMagasinCommerce.jpg';
import { colors } from '../../utils/constant';
import {
  StyledGridContainer,
  StyledGridItem,
  StyledMainBox,
  StyledOverlayBox,
  StyledTypography,
} from './serviceSectionStyle';

const ServiceSection: FC = () => {
  const theme = useTheme();
  return (
    <StyledMainBox sx={{ backgroundImage: `url(${securityStore})` }}>
      <StyledOverlayBox>
        <StyledGridContainer container>
          {/* Première section */}
          <StyledGridItem item xs={8} md={3.5} theme={theme}>
            <GrUserExpert size="2rem" color={colors.secondary} />
            <StyledTypography variant="h6">Nos domaines de compétences :</StyledTypography>
            <Typography>Gardiennage</Typography>
            <Typography>Sécurité incendie</Typography>
            <Typography>Sécurité événementielle</Typography>
            <Typography>Sécurité magasin</Typography>
            <Typography>Sécurité hôtel</Typography>
            <Typography>Sécurité mobile</Typography>
          </StyledGridItem>
          {/* Deuxième section */}
          <StyledGridItem item xs={8} md={3.5} theme={theme}>
            <FaFileSignature size="2rem" color={colors.secondary} />
            <StyledTypography variant="h6">Nos atouts :</StyledTypography>
            <Typography>Réactivité</Typography>
            <Typography>Qualités de prestation</Typography>
            <Typography>Professionnalisme</Typography>
          </StyledGridItem>
          {/* Troisième section */}
          <StyledGridItem item xs={8} md={3.5} theme={theme}>
            <FaMapMarkedAlt size="2rem" color={colors.secondary} />
            <StyledTypography variant="h6">{`Zones d'intervention :`}</StyledTypography>
            <Typography>Strasbourg</Typography>
            <Typography>Colmar</Typography>
            <Typography>Mulhouse</Typography>
            <Typography>Le Mans</Typography>
          </StyledGridItem>
        </StyledGridContainer>
      </StyledOverlayBox>
    </StyledMainBox>
  );
};

export default ServiceSection;
