import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { colors } from '../utils/constant';

const Footer: FC = () => {
  return (
    <Box component="footer" sx={{ backgroundColor: colors.primary, color: 'white', paddingY: 3 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom>
              PRESTIGE SECURITE SARL
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              2 rue gribeauval
            </Typography>
            <Typography variant="subtitle1" color="inherit" gutterBottom>
              67100 STRASBOURG
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              03 88 31 59 50
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom>
              À PROPOS
            </Typography>
            <Link href="/" color="inherit" underline="hover">
              Accueil
            </Link>
            <br />
            <Link href="/contactez-nous" color="inherit" underline="hover">
              Contactez-nous
            </Link>
            <br />
            <Link href="/mentions-legales" color="inherit" underline="hover">
              Mentions légales
            </Link>
            {/* todo element a ajouter si besoin */}
            {/* <br />*/}
            {/* <Link href="#" color="inherit" underline="hover">*/}
            {/*  Plan du site*/}
            {/* </Link>*/}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="inherit" gutterBottom>
              SUIVEZ-NOUS
            </Typography>
          </Grid>
        </Grid>
        <Box textAlign="center" paddingTop={{ xs: 5, sm: 10 }} paddingBottom={{ xs: 5, sm: 0 }}>
          PRESTIGE SÉCURITÉ - Agence de sécurité à Strasbourg
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
