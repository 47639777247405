import { Typography } from '@mui/material';
import { FC } from 'react';
import backSecurityGuard from '../assets/back-security-guard.jpg';
import extincteur from '../assets/Extincteur.jpg';
import securityGuardBlack2 from '../assets/security-guard-black-2.jpg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import security from '../assets/security.jpg';
import superintendence from '../assets/Sécuritéincendie.jpg';
import ServicePageTemplate from '../templates/ServicePageTemplate';

const SecuriteIncendie: FC = () => {
  const title = 'VOTRE AGENCE DE SÉCURITÉ À STRASBOURG';
  const bgImages = [security, securityGuardBlack, backSecurityGuard, securityGuardBlack2];

  return (
    <ServicePageTemplate
      title={title}
      bgImages={bgImages}
      firstInfo={{
        imgSrc: superintendence,
        title: 'Sécurité incendie à Strasbourg',
        subtitle: 'Nous assurons la prévention et la protection contre l’incendie.',
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Prévention</strong> <br /> et intervention
          </Typography>
        ),
        firstParagraph: (
          <Typography variant="body1" gutterBottom>
            Au service des professionnels et des particuliers, nous appliquons plusieurs règles de
            sécurité pour prévenir les risques d’incendie dans les locaux,
          </Typography>
        ),
        lastParagraph: (
          <>
            <Typography marginBottom="8px" marginTop="8px" variant="body1" gutterBottom>
              Nous nous assurons de maintenir en bon état le matériel et les installations comme les
              extincteurs, les portes coupe-feu et les alarmes.
            </Typography>
            <Typography marginBottom="16px" marginTop="8px" variant="body1" gutterBottom>
              En cas d’incendie, nous intervenons en urgence pour limiter les dégâts occasionnés par
              le feu. Nous nous chargeons d’évacuer les personnes au plus vite pour les mettre hors
              du danger. C’est aussi notre rôle d’alerter rapidement les pompiers.
            </Typography>
          </>
        ),
        caption: 'Nous portons assistance aux personnes en danger.',
      }}
      secondInfo={{
        imgSrc: extincteur,
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Prestations</strong> <br />
            de qualité
          </Typography>
        ),
        firstParagraph: `Faites appel à nos agents de sécurité incendie avertis pour sécuriser vos locaux (centres commerciaux, usines, chantiers, entrepôts, bureaux…).`,
        secondParagraph: `Nous enseignons les règles de base et les bons gestes à adopter au cas où le feu se déclenche. Vous pouvez aussi solliciter nos services lors de divers événements (concerts, foires…)`,
        thirdParagraph: `Pour assurer votre sécurité, nous vous garantissons des prestations correspondant à vos attentes. Nos zones d’intervention couvrent Strasbourg, Mulhouse, Le Mans et alentour.`,
        caption: 'Nos agents vous apprennent à adopter les bons gestes en cas d’incendie.',
      }}
    />
  );
};

export default SecuriteIncendie;
