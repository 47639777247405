import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Theme, Typography } from '@mui/material';
import { colors } from '../../../utils/constant';
import { slideInFromTop } from '../../../utils/keyframes';

export const globalStyles = css`
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    animation: ${slideInFromTop} 1s ease-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .slick-slide div {
    margin: 0 8px;
    border-radius: 15px;
  }

  .slick-slide img {
    border-radius: 18px;
  }
`;

export const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export const StyledTypography = styled(Typography)({
  color: colors.white,
  position: 'absolute',
  bottom: 5,
  width: '100%',
  textAlign: 'center',
});
