import { styled, Box, Paper, Typography } from '@mui/material';
import { colors } from '../../../utils/constant';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  textAlign: 'center',
  minHeight: '340px',
  borderRadius: '5px',
  boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
  [theme.breakpoints.up('sm')]: {
    height: '400px',
  },
}));

export const StyledImageBox = styled('img')(() => ({
  width: '100%',
  height: '80%',
  objectFit: 'cover',
  borderRadius: '5px 5px 0 0',
}));

export const StyledContentBox = styled(Box)({
  position: 'relative',
  textAlign: 'left',
  paddingLeft: '60px',
});

export const StyledIconBox = styled(Box)({
  height: '50px',
  width: '50px',
  position: 'absolute',
  left: 5,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.secondary,
  borderRadius: '50%',
});

export const StyledTitleTypography = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.25rem',
  marginLeft: '20px',
});

export const StyledDescriptionTypography = styled(Typography)({
  fontSize: '0.875rem',
  marginLeft: '20px',
});

export const StyledTrustSectionBox = styled(Box)({
  flexGrow: 1,
  backgroundColor: colors.white,
  minHeight: '80vh',
  padding: '40px 16px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const StyledTitleSectionTypography = styled(Typography)({
  textAlign: 'center',
  marginTop: '40px',
  marginBottom: '40px',
});
