import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import React, { FC, useEffect, useState } from 'react';
import { colors } from '../utils/constant';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

interface ScrollToTopButtonProps extends IconButtonProps {
  icon?: React.ReactElement;
  iconColors?: string;
  border?: string;
}

const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({
  icon = <ArrowUpwardIcon />,
  iconColors = colors.white,
  border = `1px solid ${colors.white}`,
  ...rest
}: ScrollToTopButtonProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return isVisible ? (
    <Box position="fixed" zIndex={10} bottom={24} right={24}>
      <IconButton
        {...rest}
        sx={{
          'color': iconColors,
          border,
          'zIndex': 20,
          'backgroundColor': colors.secondary,
          'position': 'fixed',
          'bottom': '20px',
          'right': '20px',
          '&:hover': {
            backgroundColor: colors.primary,
          },
        }}
        aria-label="scroll to top"
        onClick={scrollToTop}
      >
        {icon}
      </IconButton>
    </Box>
  ) : null;
};

export default ScrollToTopButton;
