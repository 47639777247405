import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource-variable/mulish';
import '@fontsource/roboto';
import Router from './navigation/Router';
import { titleStore } from './services/store/titleStore';
import { theme } from './utils/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App: FC = () => {
  const title = titleStore((state) => state.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
