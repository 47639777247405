import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Theme, Typography } from '@mui/material';
import { colors } from '../../../utils/constant';
import { slideRightFromLeft } from '../../../utils/keyframes';

export const AnimatedCenteredColumnBox = styled(Box)(({ theme }: { theme: Theme }) =>
  css({
    gap: '16px',
    display: 'flex',
    width: theme.breakpoints.down('md') ? '100vw' : '60vw',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px',
    visibility: 'hidden',
    opacity: 0,
    animation: `${slideRightFromLeft} 1s ease-out`,
    animationDelay: '1s',
    animationFillMode: 'forwards',
    [theme.breakpoints.up('sm')]: {
      padding: '16px',
    },
  }),
);

export const CenteredRowBox = styled(Box)(({ theme }: { theme: Theme }) =>
  css({
    gap: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: '16px',
    },
  }),
);

export const HeaderTitle = styled(Typography)(({ theme }: { theme: Theme }) =>
  css({
    color: colors.white,
    fontSize: theme.breakpoints.down('sm') ? 'x-large' : 'xx-large',
    fontWeight: 'bold',
    textShadow: '1px 1px 2px #000000',
  }),
);

export const HeaderSubtitle = styled(Typography)(({ theme }: { theme: Theme }) =>
  css({
    color: colors.white,
    fontSize: theme.breakpoints.down('sm') ? 'large' : 'x-large',
    fontWeight: 'bold',
    textShadow: '1px 1px 2px #000000, 0px 0px 2px #000000',
  }),
);
