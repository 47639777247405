import { keyframes } from '@emotion/react';

export const slideRightFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
`;

export const slideInFromTop = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
  1% {
    visibility: visible;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;
