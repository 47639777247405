import CallIcon from '@mui/icons-material/Call';
import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../atoms/CustomButton';
import Image from '../../atoms/Image';
import useBreakpoints from '../../services/hooks/useBreakpoints';
import { colors } from '../../utils/constant';
import { AnimatedCenteredColumnBox, CenteredColumnBox } from './headerServicesStyle';

interface HeaderServicesProps {
  title: string;
}

const HeaderServices: FC<HeaderServicesProps> = ({ title }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { downSm } = useBreakpoints();

  return (
    <AnimatedCenteredColumnBox theme={theme} width="100vw">
      <Image width={downSm ? '230px' : 'auto'} />
      <Typography
        variant="h1"
        color={colors.white}
        fontSize={{ xs: 'x-large', sm: 'xx-large' }}
        fontWeight="bold"
        style={{ textShadow: '1px 1px 2px #000000' }}
      >
        {title}
      </Typography>
      <CenteredColumnBox theme={theme}>
        <CustomButton
          startIcon={<CallIcon />}
          onClick={() => (window.location.href = 'tel:0388315950')}
        >
          03 88 31 59 50
        </CustomButton>
        <CustomButton
          backgroundColor={colors.primary}
          textColor={colors.secondary}
          onClick={() => navigate('/contactez-nous')}
        >
          Contactez-nous
        </CustomButton>
      </CenteredColumnBox>
    </AnimatedCenteredColumnBox>
  );
};

export default HeaderServices;
