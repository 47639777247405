import { createTheme } from '@mui/material/styles';
import { colors } from './constant';

export const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontFamily: 'Mulish sans-serif',
    },
  },
  palette: {
    primary: {
      main: colors.primary,
      // couleur principale pour les éléments primaires
      // light: colors.primaryLight, // couleur plus claire pour les éléments primaires
      // dark: colors.primaryDark, // couleur plus foncée pour les éléments primaires
      // contrastText: colors.primaryContrastText, // texte à afficher sur les éléments primaires
    },
    secondary: {
      main: colors.secondary,
      // light: colors.secondaryLight,
      // dark: colors.secondaryDark,
      // contrastText: colors.secondaryContrastText,
    },
    text: {
      primary: colors.black,
      secondary: colors.white,
      disabled: colors.gray,
    },
    // error: {
    // main: colors.error,
    // },
    // warning: {
    // main: colors.warning,
    // },
    // info: {
    //   main: colors.info,
    // },
    // success: {
    // main: colors.success,
    // },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          'backgroundColor': colors.primary,
          'paddingRight': 0,
          '@media (min-width:600px)': {
            // Styles pour des largeurs d'écran supérieures à 600px
          },
        },
      },
    },
  },
});
