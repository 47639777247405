import { TextField, styled } from '@mui/material';
import { colors } from '../utils/constant';

interface StyledTextFieldProps {
  labelColor?: string;
}

export const CustomTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'labelColor',
})<StyledTextFieldProps>(({ labelColor = colors.primary }) => ({
  'width': '100%',
  '& .MuiInputLabel-root': {
    'color': labelColor,
    '&.Mui-focused': { color: colors.secondary },
  },
  '& .MuiOutlinedInput-root': {
    'borderRadius': '25px',
    '& fieldset': { borderColor: colors.primary },
    '&:hover fieldset': { borderColor: colors.secondary },
    '&.Mui-focused fieldset': { borderColor: colors.secondary },
  },
}));
