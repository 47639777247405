import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { titleStore } from '../store/titleStore';

export const useDocumentTitle = () => {
  const location = useLocation();
  const setTitle = titleStore((state) => state.setTitle);

  const titlesByPath: Record<string, string> = {
    '/': 'PRESTIGE SÉCURITÉ',
    '/agent-securite': 'AGENT DE SÉCURITÉ',
    '/servicede-gardiennage': 'SERVICE DE GARDIENNAGE',
    '/securiteincendie': 'SÉCURITÉ INCENDIE',
    '/agentde-securite-evenementiel': 'AGENT DE SÉCURITÉ ÉVÉNEMENTIEL',
    '/interventionsur-alarme': 'INTERVENTION SUR ALARME',
    '/agentde-securite-magasin-commerce': 'AGENT DE SÉCURITÉ MAGASIN COMMERCE',
    '/contactez-nous': 'CONTACTEZ-NOUS',
  };

  useEffect(() => {
    const newTitle = titlesByPath[location.pathname] || 'Page non trouvée';
    setTitle(newTitle);
  }, [location, setTitle]);
};
