import CallIcon from '@mui/icons-material/Call';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../atoms/CustomButton';
import Image from '../../atoms/Image';
import useBreakpoints from '../../services/hooks/useBreakpoints';
import { colors } from '../../utils/constant';
import {
  AnimatedCenteredColumnBox,
  CenteredRowBox,
  HeaderSubtitle,
  HeaderTitle,
} from './homePageStyle/headerHomeStyle';

const HeaderHome: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { downSm } = useBreakpoints();

  return (
    <AnimatedCenteredColumnBox theme={theme}>
      <Image width={downSm ? '230px' : 'auto'} />
      <HeaderTitle variant="h1" theme={theme}>
        VOTRE AGENCE DE SÉCURITÉ À STRASBOURG
      </HeaderTitle>
      <HeaderSubtitle variant="h3" theme={theme}>
        2 Rue de Gribeauval, 67100 Strasbourg, France
      </HeaderSubtitle>
      <CenteredRowBox theme={theme}>
        <CustomButton
          startIcon={<CallIcon />}
          onClick={() => (window.location.href = 'tel:0388315950')}
        >
          03 88 31 59 50
        </CustomButton>
        <CustomButton
          backgroundColor={colors.primary}
          textColor={colors.secondary}
          onClick={() => navigate('/contactez-nous')}
        >
          Contactez-nous
        </CustomButton>
      </CenteredRowBox>
    </AnimatedCenteredColumnBox>
  );
};

export default HeaderHome;
