import { Link, Typography } from '@mui/material';
import { FC } from 'react';
import agentDeSecuriteMagasinCommerce from '../assets/agentDeSecuriteMagasinCommerce.jpg';
import agentPosition from '../assets/agentPosition.jpg';
import backSecurityGuard from '../assets/back-security-guard.jpg';
import closeProtection from '../assets/closeProtection.jpg';
import securityGuardBlack2 from '../assets/security-guard-black-2.jpg';
import securityGuardBlack from '../assets/security-guard-black.jpg';
import ServicePageTemplate from '../templates/ServicePageTemplate';
import { colors } from '../utils/constant';

const ServiceDeGardiennage: FC = () => {
  const title = 'VOTRE AGENCE DE SÉCURITÉ À STRASBOURG';
  const bgImages = [
    agentPosition,
    agentDeSecuriteMagasinCommerce,
    backSecurityGuard,
    securityGuardBlack2,
  ];

  return (
    <ServicePageTemplate
      title={title}
      bgImages={bgImages}
      firstInfo={{
        imgSrc: securityGuardBlack,
        title: 'Service de gardiennage à Strasbourg',
        subtitle: 'Notre société est spécialisée dans le domaine du gardiennage.',
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Surveillance</strong> <br /> et protection
          </Typography>
        ),
        firstParagraph: (
          <Typography variant="body1" gutterBottom>
            Nous mettons nos meilleurs agents à votre disposition pour assurer la protection de vos
            biens. Vous pouvez nous engager pour garder votre maison, immeuble,
            <Link href="#" underline="hover" color={colors.secondary}>
              {' magasin'}
            </Link>
            , chantier, parking...
          </Typography>
        ),
        lastParagraph: (
          <>
            <Typography marginBottom="8px" marginTop="8px" variant="body1" gutterBottom>
              Nous assurons le contrôle et la surveillance de l’ouverture et la fermeture des accès
              aux sites, de jour comme de nuit.
            </Typography>
            <Typography marginBottom="16px" marginTop="8px" variant="body1" gutterBottom>
              Le filtrage des personnes qui entrent et qui sortent est aussi compris dans nos
              responsabilités. Nous effectuons également des rondes techniques.
            </Typography>
          </>
        ),
        caption: 'Nous effectuons un contrôle en permanence des accès de vos locaux.',
      }}
      secondInfo={{
        imgSrc: closeProtection,
        titleParagraph: (
          <Typography fontFamily="Mulish" variant="h4" marginTop="32px" marginBottom="24px">
            <strong>Solutions sécuritaires</strong> <br />
            personnalisées
          </Typography>
        ),
        firstParagraph: `La vigilance et l’intégrité sont les mots d’ordre de notre équipe. Nous veillons à répondre aux demandes de nos clients dans les plus brefs délais.`,
        secondParagraph: `Nous apportons des solutions efficaces et spécifiques en fonction de vos besoins. Nos agents bien formés peuvent également intervenir dans d’autres domaines: sécurité incendie, événementielle, prévention vols… Nous nous déplaçons à Strasbourg, Mulhouse, Le Mans et autour.`,
        caption: 'La vigilance et l’intégrité sont nos maîtres mot.',
      }}
    />
  );
};

export default ServiceDeGardiennage;
